<template>
  <fm-poper-select v-bind="$attrs" class="org-select" @change="onChange" :options="options">
    <slot></slot>
  </fm-poper-select>
</template>

<script>
export default {
  props: {},
  computed: {
    orgList () {
      return this.$store.getters.orgList
    },
    options () {
      return this.orgList.map(org => {
        return { label: org.label, value: org.key }
      })
    }
  },
  methods: {
    onChange (v) {
      this.$emit('change', v)
    }
  },
  async mounted () {
    await this.$store.dispatch('loadOrgList')
  }
}
</script>
