<style scoped>
  .task-user {
    cursor: pointer;
  }
</style>

<template>
  <org-select v-bind="$attrs" @change="onChange">
    <div class="task-user">{{name ? name : '未指定'}} <i class="fmico fmico-paixu-jiangxu" style="margin-left: 5px;"></i></div>
  </org-select>
</template>

<script>
import OrgSelect from '../select/org'

export default {
  inheritAttrs: false,
  components: { OrgSelect },
  props: {
    id: { type: Number, default: null }
  },
  data () {
    return {
      currentId: this.id
    }
  },
  watch: {
    id (id) {
      this.currentId = id
    }
  },
  computed: {
    current () {
      return this.currentId ? this.orgList.find(v => v.key === this.currentId) : null
    },
    name () {
      return this.current ? this.current.label : null
    },
    orgList () {
      return this.$store.getters.orgList
    }
  },
  methods: {
    onChange (v) {
      this.currentId = v
      this.$emit('change', v)
    }
  }
}
</script>
